import cloneDeep from "lodash.clonedeep";
import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import useWhoIs from "../../hooks/useWhoIs";
import { useSubmitDataMutation } from "../../services/goroskop"
import ScrollDialog from "../quiz/Dialog";

import FormUser from "./constituents/formUser";
import DraggableEnhancedTable from "../admin/constituents/dragableTable";
import EnhancedTable from "../admin/constituents/Table";


const UserProfile: FC = () => {

    let { user_id } = useParams()


    const [submitData, { data, error, isLoading }] = useSubmitDataMutation()
    const [favSubData, favData ] = useSubmitDataMutation();

    let deepCloneData = (favData.data && typeof favData.data !== 'string') ? cloneDeep(favData.data) : []

    const account = useWhoIs().Account




    const newData = (favData.data && typeof favData.data !== 'string' && data && typeof data !== 'string' && deepCloneData.length) ?
        (favData.data.find(el => el.id === data[0].id)
            ? (deepCloneData.splice(0, 0, deepCloneData.splice(Number(deepCloneData.findIndex(el => el.id === data[0].id)), 1)[0]) && deepCloneData)
            : data.slice().concat(deepCloneData)) : []


    let FormDefaultValues = data ? (typeof data[0] != 'string' ? data[0] : false) : false

    useEffect (() => {
        let fd = new FormData()
        fd.append('user_id', user_id ? user_id : '')

        submitData({ name: 'profile.php', data: fd })

    }, [user_id])

    /*useEffect(() => {
        let fd = new FormData()
        //fd.append('fav', typeof FormDefaultValues !== 'boolean' ? FormDefaultValues.fav : '')
        fd.append('fav', user_id ? user_id : '')

        favSubData({ name: 'cabinet/get_favorites.php', data: fd })

    }, [FormDefaultValues])*/


    return (
        <>
            {error && <h1>Oops, error </h1>}
            {isLoading && <h1>Загрузка... </h1>}
            {FormDefaultValues && (
                <>
                    <FormUser defaultValues={FormDefaultValues} />
                </>
            )}

        </>
    )
}

export default UserProfile
