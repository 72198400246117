import React, { FC, useEffect, useState } from "react"
import { useSnackbar } from "notistack"
import { Controller, SubmitHandler, useForm, useFormState } from "react-hook-form"
import { useSubmitDataWithRerenderMutation} from "../../../services/goroskop"
import { QuizType } from "../../../types/quiz"
import FormInputRadioButtonAdmin from "../../quiz/form-component/FormInputRadioButtonsAdmin"
import FormInputTextAria from "../../quiz/form-component/FormInputTextArea"
import T from "../../quiz/OnlyText"
import {Box, styled} from "@mui/system";
import restrictRole from "../../../functions/restrictRole";
import Carousel from "../../forAll/Carousel";
import {getCookie} from "../../../functions/Cookie";
import { printUserInfo } from "./printUserInfo";
import {PrintFooterAstrodating} from "./printFooter";
const imgInputIds = ['o_img1', 'o_img2', 'o_img3', 'o_img4'];
import { Dayjs } from 'dayjs';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Card, CardActionArea, CardMedia, CardContent
} from '@mui/material';
import {printRegionName} from "../../../functions/printRegion";
import Typography from "@mui/material/Typography";
import FormInputNumber from "../../quiz/form-component/FormInputNumber";
import FormInputRadioButton from "../../quiz/form-component/FormInputRadioButtons";
import {getFamilyStatusDefault, getFamilyStatutes} from "../../../functions/printInfo";
import FormInputLangLoves from "../../quiz/form-component/FormInputLangLoves";
import FormInputLangLoves2 from "../../quiz/form-component/FormInputLangLoves2";
import Alert from "@mui/material/Alert";
interface ImmageArr {
    o_img1: File | string | null;
    o_img2: File | string | null;
    o_img3: File | string | null;
    o_img4: File | string | null
}

const imgFields:any[] = ['o_img1','o_img2','o_img3','o_img4'];
interface Props {
    defaultValues: QuizType
}

const Input = styled('input')({
    display: 'none',
});

const Form = styled('form')({
    // display: 'none',
    textAlign: 'left',
    margin: '0 auto 0 auto',
    width: '100%',
    maxWidth: '600px',
    '& > div': {
        border: '1px solid rgb(80, 61, 122)'
    },
    '& > div > label.MuiFormLabel-filled': {
        display: 'none'
    },
    '& > div > label.css-u9osun': {
        display: 'none'
    },
    '& > div > label.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root': {
        display: 'none'
    },
    // marginLeft: '25%',
});

interface userData {
    id: string | number | undefined,
    langlove: string | null | undefined,
    langlove2: string | null | undefined,
    familystatus: string | null | undefined,
    height: string | null | undefined,
    smoke: string | null | undefined,
    children: string | null | undefined,
    about: string | null | undefined,
}

const FormUser: FC<Props> = ({defaultValues}) => {
    const [submitData, { data, error }] = useSubmitDataWithRerenderMutation()
    if(defaultValues.images == null){
        defaultValues.images = '';
    }
    const imgArray:string[] = defaultValues.images.split(',')

    const [imageNameArr, setimageNameArr] = useState<string[]>([])
    const [imageArr, setImageArr] = useState<ImmageArr>({
        o_img1: imgArray[0],
        o_img2: imgArray[1],
        o_img3: imgArray[2],
        o_img4: imgArray[3]
    })
    const [usersInfo, setUsersInfo] = useState<userData[]>([]); // for doublesCheck
    /*const handlechangeLangloves = (e: React.ChangeEvent<HTMLInputElement>) => {
        //console.log('handlechangeLangloves',{langlove: usersInfo.langlove, langlove2: usersInfo.langlove2});
        console.log('handlechangeLangloves',e);
        console.log('handlechangeLangloves',{usersInfo});
    }*/

    const slider = Object.values(imageArr).map(item => {
        return (
            <img
                key={item}
                src={item && item.length > 0 && item !== 'null' ? `/upload/${item}` : '/images/noImg.jpg'}
                style={{
                    margin: '0 auto 0 auto',
                    maxWidth: '600px',
                    maxHeight: '320px'
                    // maxWidth: '45%',
                    // marginLeft: '27.5%'
            }}
                alt={item}
                loading="lazy"
            />
        )
    })

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { register, handleSubmit, formState: { errors }, control, setValue, reset } = useForm<QuizType>({
        defaultValues: defaultValues
    });

    useEffect(() => {
        try{
            // @ts-ignore
            if (typeof(data) !== "undefined" && data.files) {
                // @ts-ignore
                setImageArr(data.files);

                // @ts-ignore
                imgFields.forEach((field) => {
                    // @ts-ignore
                    imageNameArr[Object.keys(data.files).indexOf(field)] = data.files[field] ? data.files[field] : 'null'
                })
            }else{
                // При первой загрузке страницы не учитываются имена имеющихся файлов, фикс в этом блоке:
                let tmpArr:string[] = [];
                let setImgFlag = false;
                if(imageNameArr.length == 0 && imageArr){
                    if(imageArr.o_img1 != '' && typeof(imageArr.o_img1) === 'string'){
                        tmpArr[0] = imageArr.o_img1;
                        setImgFlag = true;
                    }
                    if(imageArr.o_img2 != '' && typeof(imageArr.o_img2) === 'string'){
                        tmpArr[1] = imageArr.o_img2;
                        setImgFlag = true;
                    }
                    if(imageArr.o_img3 != '' && typeof(imageArr.o_img3) === 'string'){
                        tmpArr[2] = imageArr.o_img3;
                        setImgFlag = true;
                    }
                    if(imageArr.o_img4 != '' && typeof(imageArr.o_img4) === 'string'){
                        tmpArr[3] = imageArr.o_img4;
                        setImgFlag = true;
                    }
                }
                if(setImgFlag){
                    setimageNameArr(tmpArr);
                }
            }
        }catch (e){}
    });

    const onSubmit: SubmitHandler<QuizType> = data => {
        // enqueueSnackbar('[static] Данные обновлены', {
        //     variant: 'success',
        // });

        let newData = data

        newData.images = Object.values(imageArr).join(',')
        newData.status = data.status ?? restrictRole()

        let fd = new FormData()

        fd.append('data', JSON.stringify(newData))
        //console.log(fd.get('data'));

        submitData({ name: 'profile_save.php', data: fd }).then((answer:any) => {
            console.log('answer',answer.data);
            if(answer.data){
                try{
                    enqueueSnackbar(
                        (answer.data.msg ? answer.data.msg : 'Ошибка передачи данных на сервер'),
                        {variant: answer.data.status ? 'success' : 'error'}
                    )
                }catch (e) {
                    enqueueSnackbar(
                        (answer.data.msg ? answer.data.msg : 'Ошибка передачи данных на сервер'),
                        {variant: answer.data.status ? 'success' : 'error'}
                    )
                }
            }
        })
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        let fd = new FormData()

        const file = e.target.files ? e.target.files[0] : 'null'
        const fileName = e.target.files ? e.target.files[0]?.name : 'null'
        const inputName = e.currentTarget.name

        // needed:
        imageNameArr[Object.keys(imageArr).indexOf(e.target.name)] = e.target.files ? e.target.files[0].name : 'null'

        // --------- поиск старой картнки -----------
        // Берем строку картинок пользователя, потом склеиваем, получаем массив имен картинок
        // и берем катинку из массива по индексу. Сам индекс берем из названия инпута, так же склеиваем,
        // переворачиваем и берем первый элемент массива и преобразуем в число, потом отнимаем единицу
        // все легко, не правда, ли?
        const lastNameImg = defaultValues.images.split(',')[Number(e.target.name.split('').reverse()[0]) - 1]

        let newString = defaultValues.images.split(',')
        newString[newString.indexOf(lastNameImg)] = e.target.files ? e.target.files[0]?.name : 'null'

        const imageObject = { ...imageArr, [inputName]: fileName }
        //const strOfImg = (Object.values(imageObject)).join(',')

        setValue(e.currentTarget.name as keyof QuizType, e.target.files ? e.target.files[0]?.name : 'null')
        //setValue('images', strOfImg)
        setValue('images', imageNameArr.join(','))

        fd.append(e.target.name, file)
        fd.append('user_id', defaultValues.id)
        //fd.append('images', strOfImg)
        fd.append('images', imageNameArr.join(','))

        submitData({ name: 'updatePhoto.php', data: fd })
            .finally(async () => {
                console.log('uploaded imageArr1', imageArr);
                //setImageArr({ ...imageArr, [inputName]: fileName })
                console.log('uploaded imageArr2', imageArr);
                enqueueSnackbar('Картинка изменена', {
                    variant: 'info',
                });
            })


    }

    const deleteImage = (nameImg: number) => {
        let nameImgString = Object.values(imageArr)[nameImg];
        if(nameImgString !== null && nameImgString !== '' && nameImgString !== 'undefined') {
            let fd = new FormData()

            fd.append('imgName', nameImgString)
            fd.append('user_id', defaultValues.id)

            submitData({name: 'deleteFile.php', data: fd})

            setImageArr({...imageArr, [Object.keys(imageArr)[nameImg]]: null})
        }
    }

    const role = getCookie('role');

    function showTargetSearch(){
        if(role == 'ok_admin') { // ok_mainModer
            return <>
                <T>Вид поиска</T>
                <FormInputRadioButtonAdmin
                    control={control}
                    name='targetsearch'
                    options={['Активный', 'Пассивный', 'Консультация', 'Встреча']}
                />
                <FormInputTextAria
                    style={{marginTop: '15px'}}
                    control={control}
                    label='Вид поиска'
                    name="targetsearchtext"
                />
            </>
        }
    }

    function declination(number: number) {
        let titles = [' год', ' года', ' лет'];
        let cases = [2, 0, 1, 1, 1, 2];
        return titles[ (number%100>4 && number%100<20)? 2:cases[(number%10<5)?number%10:5] ];
    }

    const getGodaLet = (count: number) => {
        const title = declination(count);
        const age = count + title;
        return age;
    }

    const getVozrast = (date: Dayjs | null, from: number, until: number) => {
        // return date; - 1971-07-27
        if(date) {
            let dd: string | number,
                mm: string | number,
                yyyy: string | number;

            if(typeof(date) === 'string'){
                //@ts-ignore
                let dateArrTmp = date.split(' ');
                let dateArr = dateArrTmp[0].split('-');
                if(dateArr.length == 3){
                    yyyy = dateArr[0];
                    mm = dateArr[1];
                    dd = dateArr[2];
                }
            }else{
                dd = (date.date());
                mm = (date.month()) + 1;
                yyyy = (date.year());
            }
            let today = new Date();
            //@ts-ignore
            let birthDate = new Date(yyyy, mm, dd);

            //console.log({dd: dd, mm: mm, yyyy: yyyy,});

            let nowYear = today.getFullYear();
            let pseudoYear = nowYear;
            let age = nowYear - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
                pseudoYear--;
            }
            return 'Клиенту ' + getGodaLet(age) +
                ', от ' + (pseudoYear - until) +
                ' до ' + (pseudoYear - from) + ' года';
        }
        return '';
    }

    /*
    const getVozrast = (date: Dayjs | null, from: number, until: number) => {
        // return date; - 1971-07-27
        if(date) {
            let dateArray = date.split('-');
            if(dateArray.length === 3){
                let key = 0,
                    dd: string | number,
                    mm: string | number,
                    yyyy: string | number;

                dateArray.forEach((el) => {
                    //@ts-ignore
                    dateArray[key] = parseInt(el);
                    switch(key){
                        case 0:{
                            yyyy = parseInt(dateArray[key]);
                        }break;
                        case 1:{
                            mm = parseInt(dateArray[key]);
                        }break;
                        case 2:{
                            dd = parseInt(dateArray[key]);
                        }break;
                    }
                    key++;
                });

                let today = new Date();
                //@ts-ignore
                let birthDate = new Date(yyyy,mm,dd);

                let nowYear = today.getFullYear();
                let pseudoYear = nowYear;
                let age = nowYear - birthDate.getFullYear();
                let m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                    pseudoYear--;
                }
                return 'Клиенту ' + getGodaLet(age) +
                    ', от ' + (pseudoYear - until) +
                    ' до ' + (pseudoYear - from) + ' года';
            }
        }
        return '';
    }
    */

    const resendEmail = (user_id: string) => {
        let userId = parseInt(user_id);
        if(userId > 0){
            let fD = new FormData();
            fD.append('id', user_id);
            submitData({ name: 'resend_email.php', data: fD })
        }
    }

    const printDate = (date: string | undefined) => {
        if(date) {
            let newDate = date.split('-');
            return newDate[2] + '.' + newDate[1] + '.' + newDate[0];
        }
        return '';
    }

    const convertDate = (date: string) => {
        if(date && date !== ''){
            let newDate = date.split(' ');
            newDate = newDate[0].split('-');
            let month = '';
            switch(newDate[1]){
                case '01':
                    month = 'января';
                    break;
                case '02':
                    month = 'февраля';
                    break;
                case '03':
                    month = 'марта';
                    break;
                case '04':
                    month = 'апреля';
                    break;
                case '05':
                    month = 'мая';
                    break;
                case '06':
                    month = 'июня';
                    break;
                case '07':
                    month = 'июля';
                    break;
                case '08':
                    month = 'августа';
                    break;
                case '09':
                    month = 'сентября';
                    break;
                case '10':
                    month = 'октября';
                    break;
                case '11':
                    month = 'ноября';
                    break;
                case '12':
                    month = 'декабря';
                    break;
            }
            return newDate[2]+' '+month;
            //return newDate[2] + '.' + newDate[1] + '.' + newDate[0];
        }
        return '';
    }

    const printButtonsAccess = () => {
        if(defaultValues.tariff != "0") {
            return false
            /*return <>
                <label htmlFor="contained-button-file1">
                    {imgInputIds.forEach(inputId => {})}
                    <Input {...register('o_img1')} onChange={handleChange} id="contained-button-file1" type="file" accept="image/jpg, image/jpeg" />
                    <Button
                        sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                        variant="contained" component="span">
                        Картинка 1
                    </Button>
                </label>
                <label htmlFor="contained-button-file2">
                    <Input {...register('o_img2')} onChange={handleChange} id="contained-button-file2" type="file" accept="image/jpg, image/jpeg" />
                    <Button
                        sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                        variant="contained" component="span">
                        Картинка 2
                    </Button>
                </label>
                <label htmlFor="contained-button-file3">
                    <Input {...register('o_img3')} onChange={handleChange} id="contained-button-file3" type="file" accept="image/jpg, image/jpeg" />
                    <Button
                        sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                        variant="contained" component="span">
                        Картинка 3
                    </Button>
                </label>
                <label htmlFor="contained-button-file4">
                    <Input {...register('o_img4')} onChange={handleChange} id="contained-button-file4" type="file" accept="image/jpg, image/jpeg" />
                    <Button
                        sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                        variant="contained" component="span">
                        Картинка 4
                    </Button>
                </label>
            </>*/
        }
        return false
    }

    const printDo = (tariff_until: any) => {
        if(defaultValues.tariff_until !== null){
            return <>, до {printDate(defaultValues.tariff_until)}</>
        }else{
            return null
        }
    }

    const showEdit = !(defaultValues.tariff === '0' || defaultValues.tariff == '14' || defaultValues.tariff == '15' || defaultValues.tariff == '16' ); // станд уч запись или замарожена

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Form onSubmit={handleSubmit(onSubmit)} sx={{flex:'1'}}>
                <T>Имя и фамилия: <b>{defaultValues.firstname}</b><br />
                Текущий тариф <b>{defaultValues.tariff_name !== 'FREEZE' ? defaultValues.tariff_name : 'Доступ к анкетам заморожен'}</b>{printDo(defaultValues.tariff_until)}
                </T>

                <T>Фото</T>
                <Carousel items={slider} defIndex={'0'} dataId={'0'} />

                {showEdit ? <T sx={{width: '100%', position: 'relative'}}>
                {
                    Object.values(imageArr).map((img, index) => {
                        /*console.log({
                            img: img,
                            index: index,
                        });*/
                        return (
                            <Card sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0" }} key={index} onClick={() => deleteImage(index)}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={
                                            (img && img !== null && img.length > 0  && img !== 'null')
                                                ? `/upload/${img}`
                                                : '/images/noImg.jpg'
                                        }
                                        alt="photo"
                                    />
                                    <CardContent >
                                        <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}} >
                                            Удалить
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        )})
                }
                </T> : null}
                {showEdit ? (
                    <>
                        <label htmlFor="contained-button-file1">
                            {/*imgInputIds.forEach(inputId => {})*/}
                            <Input {...register('o_img1')} onChange={handleChange} id="contained-button-file1" type="file" accept="image/jpg, image/jpeg" />
                            <Button
                                sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                                variant="contained" component="span">
                                Картинка 1
                            </Button>
                        </label>
                        <label htmlFor="contained-button-file2">
                            <Input {...register('o_img2')} onChange={handleChange} id="contained-button-file2" type="file" accept="image/jpg, image/jpeg" />
                            <Button
                                sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                                variant="contained" component="span">
                                Картинка 2
                            </Button>
                        </label>
                        <label htmlFor="contained-button-file3">
                            <Input {...register('o_img3')} onChange={handleChange} id="contained-button-file3" type="file" accept="image/jpg, image/jpeg" />
                            <Button
                                sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                                variant="contained" component="span">
                                Картинка 3
                            </Button>
                        </label>
                        <label htmlFor="contained-button-file4">
                            <Input {...register('o_img4')} onChange={handleChange} id="contained-button-file4" type="file" accept="image/jpg, image/jpeg" />
                            <Button
                                sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                                variant="contained" component="span">
                                Картинка 4
                            </Button>
                        </label>
                    </>
                ) : null}

                { printButtonsAccess() }


                {/*
                <T>В год какого животного вы родились?</T>
                <LangLoveList name="birthyear" control={control} setValue={setValue} />

                <T>Знак по западной астрологии</T>
                <ZodiakList name="zodiak" control={control} setValue={setValue} />
                */}
                {/*<T>Ваше семейное положение</T>
                <FormInputRadioButtonAdmin
                    control={control} name='familystatus'
                    options={['Холост', 'Не замужем', 'Женат', 'Замужем']}
                />*/}
                {/*
                <T>Желаемый возраст партнера клиента<br/>
                   {getVozrast(defaultValues.birthd, defaultValues.ageFrom, defaultValues.ageUntil)}</T>
                <FormInputText
                    control={control}
                    name='ageFrom'
                    label="Возраст от"
                />
                <FormInputText
                    control={control}
                    name='ageUntil'
                    label="Возраст до"
                />*/}
                {/*
                <T>Коментарий</T>
                <FormInputTextAria control={control} label='Комментарий' name="comment" sx={{height: '400px'}}/>

                <T>Откуда узнали обо мне</T>
                <FormInputText control={control} label='Откуда узнали обо мне' name="source" />
                */}

                {/*
                <T>Пожелания клиента</T>
                <FormInputTextAria
                    control={control}
                    name='helptext'
                    label="Пожелания клиента"
                />

                <T>Введите ваш рост</T>
                <FormInputText control={control} label='рост' name="height" />
                */}

                {printUserInfo('Внешний код', (defaultValues.user_OutNum && defaultValues.user_OutNum != '' ? defaultValues.user_OutNum : 'id'+defaultValues.id), false)}
                {defaultValues.user_InNum ? printUserInfo('Внутренний код', defaultValues.user_InNum, false) : null}
                {defaultValues.city ? printUserInfo('Регион', printRegionName(defaultValues.city), false) : null}
                {defaultValues.birthday ? printUserInfo('Дата рождения',
                    //@ts-ignore
                    defaultValues.birthday,
                    false) : null}
                {defaultValues.birthyear ? printUserInfo('Знак по восточной астрологии', defaultValues.birthyear, false) : null}
                {defaultValues.zodiak ? printUserInfo('Знак по западной астрологии', defaultValues.zodiak, false) : null}
                {!showEdit
                    ? (defaultValues.langlove ? printUserInfo('Вид поиска партнера', defaultValues.targetsearch, false) : null)
                    : <>
                        <T>Вид поиска партнера</T>
                        <FormInputRadioButtonAdmin
                            control={control}
                            name='targetsearch'
                            options={['Активный', 'Пассивный', 'Консультация', 'Встреча']}
                        />
                    </>}
                {!showEdit
                    ? (defaultValues.langlove ? printUserInfo('Основной язык любви', defaultValues.langlove, false) : null)
                    : <>
                        <T>Основной язык любви</T>
                        <FormInputLangLoves
                            control={control}
                            name='langlove'
                            forceupdate={true}
                        />
                    </>}
                {!showEdit
                    ? (defaultValues.langlove2 ? printUserInfo('Второстепенный язык любви', defaultValues.langlove2, false) : null)
                    : <>
                        <T>Второстепенный язык любви</T>
                        <FormInputLangLoves2
                            control={control}
                            name='langlove2'
                            forceupdate={true}
                        />
                    </>}
                {defaultValues.phone ? printUserInfo('Номер телефона', defaultValues.phone, false) : null}
                {defaultValues.vk ? printUserInfo('Ссылка на страницу вконтакте', defaultValues.vk, true) : null}
                {!showEdit
                    ? (defaultValues.familystatus ? printUserInfo('Семейное положение', defaultValues.familystatus, false) : null)
                    : <>
                        <T>Ваше семейное положение</T>
                        <FormInputRadioButton
                            control={control}
                            name='familystatus'
                            options={getFamilyStatutes(defaultValues.gender)}
                            defaultValue={getFamilyStatusDefault()}
                        />
                    </>}
                {defaultValues.gender ? printUserInfo('Пол', defaultValues.gender, false) : null}
                {defaultValues.targetsearch ? printUserInfo('Вид поиска партнера', defaultValues.targetsearch, false) : null}
                {!showEdit
                    ? (defaultValues.height ? printUserInfo('Рост', defaultValues.height, false) : null)
                    : <>
                        <T>Введите ваш рост, см</T>
                        <FormInputNumber
                            control={control}
                            label='Ваш рост'
                            name="height"
                        />
                    </>}
                {!showEdit
                    ? (defaultValues.smoke ? printUserInfo('С.о.о.к.к. (сведения об отношении к курению)', defaultValues.smoke, false) : null)
                    : <>
                        <Typography></Typography>
                        <T>Курение?</T>
                        <FormInputRadioButton
                            control={control}
                            name='smoke'
                            defaultValue={defaultValues.smoke}
                            options={['Да', 'Нет', 'Не указано']}
                        />
                    </>}
                {!showEdit
                    ? (defaultValues.children ? printUserInfo('С.о.н.д. (сведения о наличии детей)', defaultValues.children, false, 'Не указано') : null)
                    : <>
                        <T>Наличие детей</T>
                        <FormInputRadioButton
                            control={control}
                            name='children'
                            defaultValue={defaultValues.children}
                            options={['Да', 'Нет', 'Не указано']}
                        />
                    </>}
                {defaultValues.registermonth_new ? printUserInfo('Дата вступления в базу', convertDate(defaultValues.registermonth_new), false) : null}
                {!showEdit
                    ? (defaultValues.about ? printUserInfo('Информация о себе', defaultValues.about, false) : null)
                    : <FormInputTextAria
                        style={{marginTop: 0}}
                        name="about"
                        label='Расскажите о себе'
                        control={control}/>}
                {!showEdit ? <Alert
                        severity="warning"
                        sx={{ margin: '15px 0 0 0' }}
                >У&nbsp;вас недостаточно прав для&nbsp;редактирования профиля. Обратитесь&nbsp;в&nbsp;<a href={'https://vk.me/svastro'} target={'_blank'}>Бюро&nbsp;Счастливых&nbsp;Знакомств</a>.</Alert> : null}
                <Button type="submit" size="large" variant="contained"
                        style={{marginTop: '40px', zIndex: '1'}}
                        disabled={showEdit ? false : true}
                >Сохранить</Button>

            </Form>
            { PrintFooterAstrodating() }
        </Box>
    )
}

export default FormUser
