import React from "react";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru' // import locale
dayjs.locale('ru') // use locale
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Moscow');

import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import '../../../datepicker.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ruRU } from '@mui/x-date-pickers/locales';
import { Controller } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";
//import Stack from '@mui/material/Stack';
//const DATE_FORMAT = "дд.мм.гггг";
import { DateValidationError } from '@mui/x-date-pickers/models';
// import { deDE as dataGridDeDE } from '@mui/x-data-grid';
// import { deDE as coreDeDE } from '@mui/material/locale';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const dateFormat = 'DD.MM.YYYY';
const yearAccess = 18;
const startOfQ12022 = dayjs('1900-01-01T00:00:00.000');
const nowOfQ12022 = dayjs();
const endOfQ12022 = nowOfQ12022.subtract(yearAccess, 'year');
const FormInputDateNEW = ({ name, control, label, sx }: FormInputProps) => {
    var isBirthday = false;
    if(name === 'birthday'){
        isBirthday = true;
    }

    const [error, setError] = React.useState<DateValidationError | null>(null);

    const errorMessage = React.useMemo(() => {
        switch (error) {
            case 'maxDate':
            case 'minDate': {
                return 'Регистрация разешена для людей от '+yearAccess+' лет';
            }

            case 'invalidDate': {
                return 'Не корректная дата';
            }

            default: {
                return '';
            }
        }
    }, [error]);


    //const maxTimeRestrict = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
    // const checkANdRestrictDate = (date: string) => {
    //     return date ? (date.includes('.') ? date.split('.').reverse().join('-') : date) : ''
    // }

    const [value, setValue] = React.useState<Dayjs | null | string>(dayjs());

    /*React.useEffect(() => {
        if (value) {
            console.log('newDate',value);
            // const { current: descriptionElement } = descriptionElementRef;
            // if (descriptionElement !== null) {
            //     descriptionElement.focus();
            // }
        }
    }, [value]);*/

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value, ref } }) => {

                    // console.log(field.value);


                    return (

                        // window.screen.width > 777 ?
                        // <TextField
                        //     label={label}
                        //     type="date"
                        //     sx={sx}
                        //     InputLabelProps={{
                        //         shrink: true,
                        //     }}
                        //     value={checkANdRestrictDate(field.value)}
                        //     placeholder={DATE_FORMAT}
                        //     onChange={field.onChange}
                        // />

                        // LocalizationProvider localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
                        <LocalizationProvider
                            adapterLocale="ru"
                            dateAdapter={AdapterDayjs}
                            localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
                        >
                            <DatePicker
                                slotProps={{
                                    inputAdornment: {
                                        position: 'end'
                                    },
                                    day: {
                                        sx: {
                                            '&:hover': {
                                                backgroundColor: '#e8daef',
                                                transition: 'all 0.5s ease',
                                            },
                                            '&.MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
                                                backgroundColor: '#8e24aa'
                                            },
                                            '&.Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#8e24aa',
                                                '&:hover': {
                                                    color: '#fff',
                                                    backgroundColor: '#8e24aa',
                                                    transition: 'all 0.5s ease',
                                                },
                                                '&:focus': {
                                                    color: '#fff',
                                                    backgroundColor: '#8e24aa',
                                                    transition: 'all 0.5s ease',
                                                },
                                            },
                                            '&.Mui-selected:focus': {
                                                backgroundColor: '#8e24aa'
                                            },
                                            '&.Mui-selected:hover': {
                                                backgroundColor: '#8e24aa'
                                            },


                                            '.MuiPickersMonth-monthButton.Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#8e24aa',
                                            },
                                            '.MuiPickersYear-yearButton.Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#8e24aa',
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton:focus': {
                                                backgroundColor: '#a569bd'
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton:hover': {
                                                backgroundColor: '#e8daef'
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton.Mui-selected:focus': {
                                                backgroundColor: '#8e24aa'
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton.Mui-selected:hover': {
                                                backgroundColor: '#8e24aa'
                                            },
                                        }
                                    },
                                    desktopPaper: {
                                        sx: {
                                            '.MuiPickersMonth-monthButton.Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#8e24aa',
                                            },
                                            '.MuiPickersYear-yearButton.Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#8e24aa',
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton:focus': {
                                                backgroundColor: '#a569bd'
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton:hover': {
                                                backgroundColor: '#e8daef'
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton.Mui-selected:focus': {
                                                backgroundColor: '#8e24aa'
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton.Mui-selected:hover': {
                                                backgroundColor: '#8e24aa'
                                            },
                                        },
                                    },
                                    mobilePaper: {
                                        sx: {
                                            '.MuiPickersMonth-monthButton.Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#8e24aa',
                                            },
                                            '.MuiPickersYear-yearButton.Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#8e24aa',
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton:focus': {
                                                backgroundColor: '#a569bd'
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton:hover': {
                                                backgroundColor: '#e8daef'
                                            },
                                            '.MuiPickersYear-yearButton.Mui-selected:focus': {
                                                backgroundColor: '#8e24aa'
                                            },
                                            '.MuiPickersYear-yearButton.Mui-selected:hover': {
                                                backgroundColor: '#8e24aa'
                                            },
                                        },
                                    },
                                    textField: {
                                        helperText: errorMessage,
                                    },
                                }}
                                // defaultValue={!isBirthday ? nowOfQ12022 : null}
                                onError={(newError) => setError(newError)}
                                minDate={startOfQ12022}
                                maxDate={isBirthday ? endOfQ12022 : nowOfQ12022}
                                label={label}
                                onChange={onChange}
                                onAccept={onChange}
                                value={value}
                                inputRef={ref}
                            />
                        </LocalizationProvider>
                    )}}

            />
        </>
    );
};

export default FormInputDateNEW