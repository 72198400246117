import { Button, Card, CardActionArea, CardContent, CardMedia, styled, TextField, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import { FC, useState, useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import ReactInputMask from "react-input-mask"
import {  useSubmitDataWithRerenderMutation } from "../../services/goroskop"
import { QuizType } from "../../types/quiz"
import restrictRole from "../../functions/restrictRole"
import FormInputDate from "../quiz/form-component/FormInputDate"
import FormInputDropdown from "../quiz/form-component/FormInputDropDown"
import FormInputRadioButton from "../quiz/form-component/FormInputRadioButtons"
import FormInputText from "../quiz/form-component/FormInputText"
import FormInputTextAria from "../quiz/form-component/FormInputTextArea"
import LangLoveList from "../quiz/langLoveList"
import T from "../quiz/OnlyText"
import PopUpImage from "../quiz/PopUpImage"
import ZodiakList from "../quiz/zodiakList"
import Carousel from "../forAll/Carousel"
import FormInputReactMask from "../quiz/form-component/FormInputReactMask"
import {getCookie} from "../../functions/Cookie";
import FormInputLangLoves from "../quiz/form-component/FormInputLangLoves";
import FormInputLangLoves2 from "../quiz/form-component/FormInputLangLoves2";


interface ImmageArr {
    o_img1: File | null;
    o_img2: File | null;
    o_img3: File | null;
    o_img4: File | null
}

const Form = styled('form')({
    // display: 'none',
    textAlign: 'left',
    maxWidth: '45%',
    '& > div': {
        border: '1px solid rgb(80, 61, 122)'
    },
    '& > div > label.MuiFormLabel-filled': {
        display: 'none'
    },
    '& > div > label.css-u9osun': {
        display: 'none'
    },
    '& > div > label.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root': {
        display: 'none'
    },
    marginLeft: '25%',
});

const Input = styled('input')({
    display: 'none',
});

const ProfileAdd: FC = () => {

    const [imageArr, setImageArr] = useState<ImmageArr>({
        o_img1: null,
        o_img2: null,
        o_img3: null,
        o_img4: null
    })
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [submitData, { data, error, isError, isSuccess }] = useSubmitDataWithRerenderMutation()

    const slider = Object.values(imageArr).map(item => {
        return (
            <img
                key={item}
                src={item && item !== 'null' ? item : '/images/noImg.jpg'}
                style={{ maxWidth: '45%', marginLeft: '27.5%' }}
                alt={item}
                loading="lazy"
            />
        )


    })

    const { register, handleSubmit, watch, formState: { errors }, control, setValue } = useForm<QuizType>({
        defaultValues: {
            fav: "",
            fav_date: "",
            fav_modify: "",
            comp: "[]",
            firstname: "",
            gender: "М",
            city: "",
            birthday: "",
            year: "",
            birthyear: "",
            zodiak: "",
            langlove: "",
            langlove2: "",
            familystatus: "",
            phone: "",
            vk: "",
            helptext: "",
            targetsearch: "",
            targetsearchtext: "",
            about: "",
            height: "",
            weight: "",
            inst: "",
            fb: "",
            ok: "",
            email: "",
            pass: "",
            r_pass: "",
            dateofend: "",
            source: "",
            source_type: "Квиз",
            images: "",
            o_img1: "",
            o_img2: "",
            o_img3: "",
            o_img4: "",
            registermonth_new: "",
            smoke: "",
            children: "",
            birthdaychild1: "",
            birthdaychild2: "",
            birthdaychild3: "",
            birthdaychild4: "",
            lastlove: "",
            lastzodiak: "",
            defer: "0",
            report: "",
            politic: false,
            vip: "0",
            ageFrom: 18,
            ageUntil: 100,
        }
    });

    useEffect(() => {
        if(isSuccess) {
            // @ts-ignore
            if(typeof(data.status) === "boolean"){
                // @ts-ignore
                enqueueSnackbar(data.msg, {variant: (data.status === true ? 'success' : 'error') });
                console.log('data', data)
            }
        }
    }, [data]);
    const onSubmit: SubmitHandler<QuizType> = data => {

        //console.log('data', data)
        // @ts-ignore
        // if(typeof(data.status) === "boolean"){
        //     // @ts-ignore
        //     enqueueSnackbar(data.msg, {variant: (data.status === true ? 'success' : 'error') });
        //     console.log('data', data)
        // }

        let newData = data;

        //console.log(data.status);


        newData.status = data.status ?? restrictRole()

        let fd = new FormData()
        fd.append('data', JSON.stringify(newData))
        fd.append('o_img1', data.o_img1)
        fd.append('o_img2', data.o_img2)
        fd.append('o_img3', data.o_img3)
        fd.append('o_img4', data.o_img4)
        console.log(fd.get('data'));

        submitData({ name: 'register.php', data: fd })
            /*.then(response => {
            // @ts-ignore
            if(response.data.isSuccess) {
                // @ts-ignore
                let res = response.data.data;
                console.log('res', res);
                if (res && res.status) {
                    if (typeof (res.status) === "boolean" && res.msg && res.msg.length > 0) {
                        enqueueSnackbar(res.msg, {variant: (res.status === true ? 'success' : 'error')});
                        console.log('data', res)
                    }
                }
            }
        })*/
        //console.log(data.o_img1)
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const fR = new FileReader()

        e.target.files && fR.readAsDataURL(e.target.files[0])


        fR.onload = () => {
            setImageArr({ ...imageArr, [e.target.name]: fR.result })
        }

        const nameOfImg = e.target.name === 'o_img2' || e.target.name === 'o_img3' || e.target.name ===  'o_img4'  ? e.target.name : 'o_img1'
        const file = e.target.files ? e.target.files[0] : ''

        // console.log(nameOfImg, file);


        setValue(nameOfImg, file)


    }

    const deleteImage = (nameImg: number) => {
        setImageArr({ ...imageArr, [Object.keys(imageArr)[nameImg]]: null })
    }

    const role = getCookie('role');

    function showTargetSearch(){
       //if(role == 'ok_admin') {
           return <>
               <T>Вид поиска</T>
               <FormInputRadioButton
                   control={control}
                   name='targetsearch'
                   options={['Активный', 'Пассивный', 'Консультация', 'Встреча']}
               />
               <FormInputTextAria
                   style={{marginTop: '15px'}}
                   control={control}
                   label='Вид поиска'
                   name="targetsearchtext"
               />
           </>
       //}
    }


    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)} style={{textAlign: 'left', maxWidth: '45%',
                marginLeft: '25%'}}>
                <Carousel items={slider} defIndex={'0'} dataId={'0'}  />
                <T>Имя и фамилия </T>
                <FormInputText
                    control={control} name='firstname' label="Имя и фамилия"
                    rules={{ required: true }}
                />

                <T>Email</T>
                <FormInputText
                    control={control}
                    label='Email клиента'
                    name="email" />

                <T>Номер телефона</T>
                <FormInputReactMask control={control} name='phone' />

                {
                    Object.values(imageArr).map((img, index) => (
                        <Card sx={{ minWidth: '21%', margin: '1%', maxWidth: "23%", display: 'inline-block' }} key={index} onClick={() => deleteImage(index)}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={img && img !== 'null' ? img : '/images/noImg.jpg'}
                                    alt="Фото нет"
                                />
                                <CardContent >
                                    <Typography gutterBottom variant="h5" component="div" >
                                        Delete
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))
                }

                <label htmlFor="contained-button-file1">
                    <Input {...register('o_img1')} onChange={handleChange} id="contained-button-file1" type="file" accept="image/jpg, image/jpeg" />
                    <Button variant="contained" component="span">
                        Картинка 1
                    </Button>
                </label>
                <label htmlFor="contained-button-file2">
                    <Input {...register('o_img2')} onChange={handleChange} id="contained-button-file2" type="file" accept="image/jpg, image/jpeg" />
                    <Button variant="contained" component="span">
                        Картинка 2
                    </Button>
                </label>
                <label htmlFor="contained-button-file3">
                    <Input {...register('o_img3')} onChange={handleChange} id="contained-button-file3" type="file" accept="image/jpg, image/jpeg" />
                    <Button variant="contained" component="span">
                        Картинка 3
                    </Button>
                </label>
                <label htmlFor="contained-button-file4">
                    <Input {...register('o_img4')} onChange={handleChange} id="contained-button-file4" type="file" accept="image/jpg, image/jpeg" />
                    <Button variant="contained" component="span">
                        Картинка 4
                    </Button>
                </label>

                <T>Дата рождения</T>
                <FormInputDate control={control} label='День рождения' name="birthday" />


                <T>В год какого животного вы родились?</T>
                <LangLoveList name="birthyear" control={control} setValue={setValue} />

                <T>Знак по западной астрологии</T>
                <ZodiakList name="zodiak" control={control} setValue={setValue} />

                {/*<T>Основной язык любви</T>
                <FormInputRadioButton
                    control={control} name='langlove'
                    options={['Одобрение', 'Время', 'Помощь', 'Подарки' ]}
                />

                <T>Второстепенный язык любви</T>
                <FormInputRadioButton
                    control={control} name='langlove2'
                    options={['Одобрение', 'Прикосновения','Время', 'Помощь', 'Подарки' ]}
                />*/}

                <T>Основной язык любви</T>
                <FormInputLangLoves
                    control={control}
                    name='langlove'
                />

                <T>Второстепенный язык любви</T>
                <FormInputLangLoves2
                    control={control}
                    name='langlove2'
                    forceupdate={true}
                />

                <T>Ваше семейное положение</T>
                <FormInputRadioButton
                    control={control}
                    name='familystatus'
                    options={['Холост', 'Не замужем', 'Женат', 'Замужем']}
                />

                <T>Введите ссылку на страницу Vkontakte</T>
                <FormInputText control={control} label='Ссылка вконтакте' name="vk" />

                <T>Коментарий</T>
                <FormInputTextAria control={control} label='Комментарий' name="comment" sx={{ height: '400px' }} />

                <T>Откуда узнали обо мне</T>
                <FormInputTextAria control={control} label='Откуда узнали обо мне' name="source" />

                <T>Информация о клиенте</T>
                <FormInputTextAria
                    name="about"
                    label="Информация о клиенте"
                    control={control}
                />

                <T>Рост</T>
                <FormInputText control={control} label='рост' name="height" />

                <T>Вес</T>
                <FormInputText control={control} label='вес' name="weight" />

                <T>Пол</T>
                <FormInputDropdown
                    control={control} name='gender' label="Выберите пол"
                    options={[{ value: 'М' }, { value: 'Ж' }]}
                />

                <T>Социальные сети</T>
                <FormInputText control={control} label='Ссылка Instagram' name="inst" />
                <FormInputText control={control} label='Ссылка Facebook' name="fb" />
                <FormInputText control={control} label='Ссылка Одноклассники' name="ok" />

                <T>Дата вступления в базу</T>
                <FormInputDate control={control} label='Выберите дату заполнения анкеты' name="reg_date" />

                {/*<T>
                    Месяц вступления в базу
                </T>
                <FormInputText control={control} label='Месяц вступления в базу' name="registermonth_new" />*/}

                <T>Курение?</T>
                <FormInputRadioButton
                    control={control}
                    name='smoke'
                    options={['Да', 'Нет', 'Не указано']}
                />

                <T>Наличие детей</T>
                <FormInputRadioButton
                    control={control}
                    name='children'
                    options={['Да', 'Нет', 'Не указано']}
                />

                <T>
                    Дата рождения детей и их пол
                </T>
                <FormInputTextAria
                    control={control}
                    name='birthdaychild1'
                    label="День рождение 1 ребенка"
                />
                <FormInputTextAria
                    control={control}
                    name='birthdaychild2'
                    label="День рождение 2 ребенка"
                />
                <FormInputTextAria
                    control={control}
                    name='birthdaychild3'
                    label="День рождение 3 ребенка"
                />
                <FormInputTextAria
                    control={control}
                    name='birthdaychild4'
                    label="День рождение 4 ребенка"
                />

                <T>Пожелания клиента</T>
                <FormInputTextAria
                    control={control}
                    label='Пожелания клиента'
                    name='helptext'
                />

                <T>Желаемый возраст партнера клиента</T>
                <FormInputText
                    control={control}
                    name='ageFrom'
                    label="Возраст от"
                />
                <FormInputText
                    control={control}
                    name='ageUntil'
                    label="Возраст до"
                />

                {showTargetSearch()}

                <T>Финансовый отчет</T>
                <FormInputText control={control} label='Финансовый отчет' name="report" />

                <T>Дата окончания отношений</T>
                <FormInputDate control={control} label='Дата окончания отношений' name="dateofend" />

                <T>Бывшие в год какого животного родились?</T>
                <LangLoveList name="lastlove" control={control} setValue={setValue} />

                <T>Бывшие: знак зодиака по западной астрологии</T>
                <ZodiakList name="lastzodiak" control={control} setValue={setValue} />

                <T>Пароль</T>
                <FormInputText control={control} label='Пароль' name="pass" />


                <T>Внешний код</T>
                <FormInputText control={control} label='Код регион' name="user_OutNum" />

                <T>Внутренний код</T>
                <FormInputText control={control} label='Код регион' name="user_InNum" />

                <T>Регион</T>
                <PopUpImage name="region_code.jpg" alt="Код региона Россия" />
                <FormInputText control={control} label='Код регион' name="city" />

                <hr />

                <Button type="submit" variant="contained" >Сохранить</Button>
                <Button variant="contained">отправить</Button>
            </Form>
        </>
    )
}

export default ProfileAdd
