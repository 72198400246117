import React, { useState, useEffect } from 'react';
import { Badge, CircularProgress, Box, List, ListItem, ListItemText, Avatar, Typography, IconButton, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import '../../chat.css';
import { getCookie } from "../../functions/Cookie";
import socket from '../../services/socket'; // Импортируйте ваш сокет

const apiChat = '/api/im';

interface Chat {
    profile_image: string;
    user_id: string;
    other_user_id: string;
    firstname: string;
    images: string;
    archived: boolean;
    unread_count: number;
}

interface Message {
    content: string;
    sender: string;
    created_at: string;
}

type ButtonType = {
    chatId: string;
    label: string; // Текст кнопки
    archived: boolean; // Статус архивирования
};

const ChatApp: React.FC = () => {
    const [chats, setChats] = useState<Chat[]>([]);
    const [currentChat, setCurrentChat] = useState<Chat | null>(null);
    const [messages, setMessages] = useState<Message[]>([]);
    const [newMessage, setNewMessage] = useState('');
    const [loadingMore, setLoadingMore] = useState(false);
    const [showArchived, setShowArchived] = useState(false);
    const [chatToBlock, setChatToBlock] = useState<Chat | null>(null);
    const [openBlockDialog, setOpenBlockDialog] = useState(false);
    const token = getCookie('token');
    const userId = getCookie('uid');
    const [buttons, setButtons] = useState<ButtonType[]>([]);

    const displayNewMessage = (newMessage: Message) => {
        if (currentChat?.other_user_id === newMessage.sender) {
            setMessages(prevMessages => [...prevMessages, newMessage]);
            //setMessages(prevMessages => [...response.data, ...prevMessages]);
        }
    };
    useEffect(() => {
        socket.on('newMessage', displayNewMessage);

        // Очистка при размонтировании компонента
        return () => {
            socket.off('newMessage', displayNewMessage);
        };
    }, []);

    useEffect(() => {
        // Инициализация кнопок при загрузке чатов
        const initialButtons = chats.map(chat => ({
            chatId: chat.other_user_id,
            label: chat.archived ? 'Восстановить' : 'В архив',
            archived: chat.archived,
        }));
        //console.log('initialButtons',initialButtons);
        setButtons(initialButtons);
    }, [chats]);

    const selectOther = (a: string | null, b: string | null) => {
        // Преобразуем userId в число для корректного сравнения
        const numericUserId = Number(userId);
        let result = null;

        // Если a или b существует, то приводим их к числу и сравниваем
        if (a !== null && Number(a) !== numericUserId) {
            result = a;
        }
        if (b !== null && Number(b) !== numericUserId) {
            result = b;
        }
        console.log('selectOther', { a:a,b:b,numericUserId:numericUserId, result:result });
        return result;
    };


    useEffect(() => {
        fetchChats();
    }, []);

    useEffect(() => {
        if (currentChat) {
            let curChatUserId = currentChat.user_id.toString();
            // В случае если chatId равен нашему ID, то подгружаем другой ID
            const chatPartnerId = curChatUserId === userId ? currentChat.other_user_id : currentChat.user_id;
            fetchMessages(chatPartnerId);
        }
    }, [currentChat, userId]);

    const fetchChats = async () => {
        try {
            const response = await axios.get<Chat[]>(`${apiChat}/chats`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setChats(response.data);
        } catch (error) {
            console.error('Error fetching chats:', error);
        }
    };

    const fetchMessages = async (chatPartnerId: number | string) => {
        try {
            setLoadingMore(true);
            const response = await axios.get(`${apiChat}/chats/${chatPartnerId}/messages`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMessages(response.data);
            setLoadingMore(false);
            setChats(prevChats =>
                prevChats.map(chat =>
                    chat.other_user_id === chatPartnerId || chat.user_id === chatPartnerId
                        ? { ...chat, unread_count: 0 }  // Обнуляем количество непрочитанных сообщений
                        : chat
                )
            );
        } catch (error) {
            console.error('Error fetching messages:', error);
            setLoadingMore(false);
        }
    };

    const loadMoreMessages = async () => {
        try {
            setLoadingMore(true);

            if (currentChat) {
                // Проверяем, чьи данные подгружать
                const chatPartnerId = currentChat.user_id === userId ? currentChat.other_user_id : currentChat.user_id;

                const response = await axios.get(`${apiChat}/chats/${chatPartnerId}/messages`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { offset: messages.length, limit: 20 },
                });

                setMessages(prevMessages => [...response.data, ...prevMessages]);
            }

            setLoadingMore(false);
        } catch (error) {
            console.error('Error loading more messages:', error);
            setLoadingMore(false);
        }
    };


    const handleChatSelect = (chat: Chat) => {
        if (currentChat?.other_user_id === chat.other_user_id) {
            return; // Не очищаем сообщения, если выбрали уже активный чат
        }
        setCurrentChat(chat);
        setMessages([]); // Reset messages when switching chats

        setButtons(prevButtons => prevButtons.map(button =>
            button.chatId === chat.other_user_id ? { ...button, archived: chat.archived } : button
        ));
    };

    const handleNewMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewMessage(event.target.value);
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() && currentChat) {
            const newMsg = {
                content: newMessage,
                sender: 'Me',
                created_at: new Date().toISOString(),
            };

            try {
                let curChatUserId = currentChat.user_id.toString();
                const chatPartnerId = curChatUserId === userId ? currentChat.other_user_id : currentChat.user_id;

                const response = await axios.post(
                    `${apiChat}/chats/${chatPartnerId}/messages`,
                    {
                        content: newMessage,
                        recipient: chatPartnerId,
                        owner: 'Me',
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );

                if (response.status === 200) {
                    setMessages([...messages, newMsg]);
                    setNewMessage('');

                    // Сообщение успешно отправлено
                    // console.log('Message sent:', response.data);
                }
            } catch (error) {
                console.error('Error sending message:', error);
                alert('Ошибка при отправке сообщения');
            }
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Предотвращаем стандартное поведение (новая строка в поле ввода)
            handleSendMessage(); // Отправляем сообщение
        }
    };

    /*const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        if (scrollTop === 0 && !loadingMore) {
            loadMoreMessages();
        }
    };*/
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        const scrollHeight = event.currentTarget.scrollHeight;
        const clientHeight = event.currentTarget.clientHeight;
        if (scrollTop === 0 && !loadingMore && scrollHeight > clientHeight) {
            loadMoreMessages();
        }
    };


    const handleToggleArchive = () => {
        setShowArchived(prev => !prev);
    };

    // Filter chats based on archive toggle
    //const filteredChats = chats.filter(chat => chat.archived === showArchived);
    const filteredChats = chats.filter(chat => {
        //console.log('Filtering chat:', chat); // Вывод информации о каждом чате
        return chat.archived === showArchived;
    });

    // Function to format the date
    const formatDate = (created_at: string) => {
        const date = new Date(created_at);

        // Форматируем дату с полным годом
        const formattedDate = date.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        // Форматируем время с секундами
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Возвращаем полную дату и время
        return `${formattedDate} ${hours}:${minutes}:${seconds}`;
    };

    const handleArchiveChat = async (chatId: string | null) => {
        if (!chatId) {
            console.error('Chat ID is missing');
            return;
        } else {
            try {
                const response = await axios.post(
                    `${apiChat}/archive-chat`,
                    {otherUserId: chatId},
                    {
                        headers: {Authorization: `Bearer ${token}`},
                    }
                )
                if (response.status === 200) {
                    // После восстановления из архива, обновляем статус чата и его кнопки
                    setChats(prevChats => prevChats.map(chat =>
                        chat.other_user_id === chatId
                            ? { ...chat, archived: true }
                            : chat
                    ));
                    // Обновляем кнопки, если они зависят от состояния archived
                    setButtons(prevButtons => prevButtons.map(button => ({ ...button, archived: true })));
                    if (currentChat && currentChat.other_user_id === chatId) {
                        setCurrentChat(prevChat => prevChat ? { ...prevChat, archived: true } : null);
                    }
                }

                //fetchChats(); // Обновляем список чатов после архивирования
            } catch (error) {
                console.error('Ошибка при архивировании чата:', error);
            }
        }
    };

    const handleUnarchiveChat = async (chatId: string | null) => {
        if (!chatId) {
            console.error('Chat ID is missing');
            return;
        } else {
            try {
                const response = await axios.post(
                    `${apiChat}/unarchive-chat`,
                    {otherUserId: chatId},
                    {
                        headers: {Authorization: `Bearer ${token}`},
                    }
                )
                if (response.status === 200) {
                    // После архивирования, обновляем статус чата и его кнопки
                    setChats(prevChats => prevChats.map(chat =>
                        chat.other_user_id === chatId
                            ? { ...chat, archived: false }
                            : chat
                    ));
                    // Обновляем кнопки, если они зависят от состояния archived
                    setButtons(prevButtons => prevButtons.map(button => ({ ...button, archived: false })));
                    if (currentChat && currentChat.other_user_id === chatId) {
                        setCurrentChat(prevChat => prevChat ? { ...prevChat, archived: false } : null);
                    }
                }
                //fetchChats(); // Обновляем список чатов после восстановления из архива
            } catch (error) {
                console.error('Ошибка при восстановлении чата №' + chatId + ':', error);
            }
        }
    };

    const handleBlockChat = async () => {
        // Проверяем, что chatToBlock и его поле other_user_id существуют
        if (chatToBlock && chatToBlock.other_user_id) {
            try {
                // Отправляем запрос на блокировку с ID заблокированного пользователя
                await axios.post(
                    `${apiChat}/block`,
                    { blockedUserId: chatToBlock.other_user_id },  // Передаем только ID
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                // Обновляем список чатов, удаляя заблокированный чат из отображения
                setChats(prevChats => prevChats.filter(chat => chat.other_user_id !== chatToBlock.other_user_id));
                // Закрытие диалогового окна после успешной блокировки
                setOpenBlockDialog(false);
                // Сброс переменной chatToBlock
                setChatToBlock(null);
                // Сбросить активный чат (делаем так, чтобы текущий чат стал неактивным)
                setCurrentChat(null);
            } catch (error) {
                if (error.response) {
                    // Сервер вернул ошибку
                    console.error('Ошибка при блокировке чата:', error.response.data.error);
                    alert(`Ошибка: ${error.response.data.error}`);  // Показываем ошибку пользователю
                } else {
                    // Если ошибка не от сервера, выводим ошибку
                    console.error('Ошибка при блокировке чата:', error.message);
                    alert('Произошла ошибка при блокировке чата');
                }
            }
        } else {
            console.error('chatToBlock не найден или не содержит правильный ID',chatToBlock);
            alert('Ошибка: Не выбран чат для блокировки');
        }
    };



    const handleOpenBlockDialog = (chatId: string | null) => {
        // Find the chat object by its ID
        const chat = chats.find(c => c.other_user_id === chatId) || null;
        console.log('handleOpenBlockDialog', chatId)
        setChatToBlock(chat); // Set the entire Chat object or null
        console.log('chatToBlock', chatToBlock)
        setOpenBlockDialog(true);
    };

    const handleCloseBlockDialog = () => {
        setOpenBlockDialog(false);
        setChatToBlock(null); // Сброс переменной, если диалог закрывается без блокировки
    };

    return (
        <Box className="chat-container">
            <Box className="chat-sidebar">
                <Button onClick={handleToggleArchive}>
                    {showArchived ? 'Показать неархивные' : 'Показать архив'}
                </Button>
                <List>
                    {filteredChats.map(chat => (
                        <ListItem
                            key={chat.other_user_id}
                            button
                            onClick={() => handleChatSelect(chat)}
                            className={`chat-item ${currentChat?.other_user_id === chat.other_user_id ? 'active' : ''}`}
                        >
                            {/*currentChat.unread_count > 0 ? currentChat.unread_count : null*/}
                            <Badge anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} badgeContent={chat.unread_count > 0 ? chat.unread_count : null} max={99} color="primary">
                                <Avatar className="chat-avatar" src={'/upload/' + chat.profile_image} alt={chat.firstname} />
                            </Badge>
                            <ListItemText className="chat-name" primary={chat.firstname} />
                        </ListItem>
                    ))}
                </List>
            </Box>

            {/* Когда выбран чат, показываем кнопки "В архив" и "Заблокировать" */}
            {currentChat && (
                <Box className="chat-main">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                        <Typography variant="h6">{currentChat.firstname}</Typography>
                        <Box>
                            <IconButton
                                key={currentChat.other_user_id}
                                color="primary"
                                onClick={() => currentChat.archived
                                    ? handleUnarchiveChat(selectOther(currentChat.user_id, currentChat.other_user_id))
                                    : handleArchiveChat(selectOther(currentChat.user_id, currentChat.other_user_id))}
                            >
                                <Typography variant="body2">
                                    {currentChat.archived ? 'Восстановить' : 'В архив'}
                                </Typography>
                            </IconButton>
                            <IconButton color="error" onClick={() => handleOpenBlockDialog(selectOther(currentChat.user_id, currentChat.other_user_id))}>
                                <Typography variant="body2">Заблокировать</Typography>
                            </IconButton>
                        </Box>
                    </Box>

                    <Box className="messages" onScroll={handleScroll}>
                        {loadingMore && <CircularProgress size={24} className="loading-spinner" />}
                        {messages.map((msg, index) => (
                            <Box key={index} sx={{ marginBottom: 2, display: 'flex', justifyContent: msg.sender === 'Me' ? 'flex-end' : 'flex-start' }}>
                                {msg.sender !== 'Me' && (
                                    <Avatar alt={currentChat?.firstname} src={'/upload/' + currentChat?.profile_image} sx={{ marginRight: 1 }} />
                                )}
                                <Box sx={{ maxWidth: '70%', backgroundColor: msg.sender === 'Me' ? '#e0e0e0' : '#f0f0f0', padding: 1, borderRadius: 2 }}>
                                    <Typography variant="body2">{msg.content}</Typography>
                                    <Typography variant="caption" color="textSecondary" align="right">
                                        {formatDate(msg.created_at)}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={2}
                            value={newMessage}
                            onChange={handleNewMessageChange}
                            onKeyDown={handleKeyDown}
                            sx={{ marginRight: 1 }}
                        />
                        <IconButton color="primary" onClick={handleSendMessage} disabled={!newMessage.trim()}>
                            <SendIcon />
                        </IconButton>
                    </Box>
                </Box>
            )}
            {/* Модальное окно подтверждения блокировки */}
            <Dialog open={openBlockDialog} onClose={handleCloseBlockDialog}>
                <DialogTitle>Подтверждение блокировки</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Вы уверены, что хотите заблокировать {currentChat ? currentChat.firstname : 'пользователя'}?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseBlockDialog} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleBlockChat} color="error">
                        Заблокировать
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ChatApp;
