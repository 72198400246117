const regionCodes = [
    {value: '77',    label: 'Москва'},
    {value: '177',   label: 'Московская область'},
    {value: '78',    label: 'Санкт-Петербург'},
    {value: '19',    label: 'Абакан'},
    {value: '122',   label: 'Алтайский край'},
    {value: '128',   label: 'Амурская область'},
    {value: '29',    label: 'Архангельск'},
    {value: '129',   label: 'Архангельская область'},
    {value: '30',    label: 'Астрахань'},
    {value: '130',   label: 'Астраханская область'},
    {value: '94',    label: 'Байконур'},
    {value: '22',    label: 'Барнаул'},
    {value: '31',    label: 'Белгород'},
    {value: '131',   label: 'Белгородская область'},
    {value: '79',    label: 'Биробиджан'},
    {value: '28',    label: 'Благовещенск'},
    {value: '32',    label: 'Брянск'},
    {value: '132',   label: 'Брянская область'},
    {value: '53',    label: 'Великий Новгород'},
    {value: '25',    label: 'Владивосток'},
    {value: '15',    label: 'Владикавказ'},
    {value: '33',    label: 'Владимир'},
    {value: '133',   label: 'Владимирская область'},
    {value: '34',    label: 'Волгоград'},
    {value: '134',   label: 'Волгоградская область'},
    {value: '35',    label: 'Вологода'},
    {value: '135',   label: 'Вологодская область'},
    {value: '36',    label: 'Воронеж'},
    {value: '136',   label: 'Воронежская область'},
    {value: '4',     label: 'Горно-Алтайск'},
    {value: '80',    label: 'Донецк'},
    {value: '180',   label: 'Донецкая Народная Республика'},
    {value: '179',   label: 'Еврейская автономная область'},
    {value: '66',    label: 'Екатеринбург'},
    {value: '175',   label: 'Забайкальский край'},
    {value: '185',   label: 'Запорожская область'},
    {value: '85',    label: 'Запорожье'},
    {value: '37',    label: 'Иваново'},
    {value: '137',   label: 'Ивановская область'},
    {value: '18',    label: 'Ижевск'},
    {value: '118',   label: 'Республика Удмуртская'},
    {value: '38',    label: 'Иркутск'},
    {value: '138',   label: 'Иркутская область'},
    {value: '12',    label: 'Йошкар-Ола'},
    {value: '16',    label: 'Казань'},
    {value: '39',    label: 'Калининград'},
    {value: '139',   label: 'Калининградская область'},
    {value: '40',    label: 'Калуга'},
    {value: '140',   label: 'Калужская область'},
    {value: '141',   label: 'Камчатский край'},
    {value: '42',    label: 'Кемерово'},
    {value: '142',   label: 'Кемеровская область'},
    {value: '43',    label: 'Киров'},
    {value: '143',   label: 'Кировская область'},
    {value: '44',    label: 'Кострома'},
    {value: '144',   label: 'Костромская область'},
    {value: '23',    label: 'Краснодар'},
    {value: '123',   label: 'Краснодарский край'},
    {value: '24',    label: 'Красноярск'},
    {value: '124',   label: 'Красноярский край'},
    {value: '182',   label: 'Крым'},
    {value: '45',    label: 'Курган'},
    {value: '145',   label: 'Курганская область'},
    {value: '46',    label: 'Курск'},
    {value: '146',   label: 'Курская область'},
    {value: '17',    label: 'Кызыл'},
    {value: '178',   label: 'Ленинградская область'},
    {value: '48',    label: 'Липецк'},
    {value: '148',   label: 'Липецкая область'},
    {value: '81',    label: 'Луганск'},
    {value: '181',   label: 'Луганская Народная Республика'},
    {value: '49',    label: 'Магадан'},
    {value: '149',   label: 'Магаданская область'},
    {value: '6',     label: 'Магас'},
    {value: '1',     label: 'Майкоп'},
    {value: '5',     label: 'Махачкала'},
    {value: '77',    label: 'Москва'},
    {value: '177',   label: 'Московская область'},
    {value: '51',    label: 'Мурманск'},
    {value: '151',   label: 'Мурманская область'},
    {value: '7',     label: 'Нальчик'},
    {value: '83',    label: 'Нарьян-Мар'},
    {value: '183',   label: 'Ненецкий автономный округ'},
    {value: '152',   label: 'Нижегородская область'},
    {value: '52',    label: 'Нижний Новгород'},
    {value: '153',   label: 'Новгородская область'},
    {value: '54',    label: 'Новосибирск'},
    {value: '154',   label: 'Новосибирская область'},
    {value: '55',    label: 'Омск'},
    {value: '155',   label: 'Омская область'},
    {value: '57',    label: 'Орёл'},
    {value: '56',    label: 'Оренбург'},
    {value: '156',   label: 'Оренбургская область'},
    {value: '157',   label: 'Орловская область'},
    {value: '58',    label: 'Пенза'},
    {value: '158',   label: 'Пензенская область'},
    {value: '159',   label: 'Пермский край'},
    {value: '59',    label: 'Пермь'},
    {value: '10',    label: 'Петрозаводск'},
    {value: '41',    label: 'Петропавловск-Камчатский'},
    {value: '125',   label: 'Приморский край'},
    {value: '60',    label: 'Псков'},
    {value: '160',   label: 'Псковская область'},
    {value: '101',   label: 'Республика Адыгея (Адыгея)'},
    {value: '104',   label: 'Республика Алтай'},
    {value: '102',   label: 'Республика Башкортостан'},
    {value: '103',   label: 'Республика Бурятия'},
    {value: '105',   label: 'Республика Дагестан'},
    {value: '106',   label: 'Республика Ингушетия'},
    {value: '107',   label: 'Республика Кабардино-Балкарская'},
    {value: '108',   label: 'Республика Калмыкия'},
    {value: '109',   label: 'Республика Карачаево-Черкесская'},
    {value: '110',   label: 'Республика Карелия'},
    {value: '111',   label: 'Республика Коми'},
    {value: '182',   label: 'Республика Крым'},
    {value: '112',   label: 'Республика Марий Эл'},
    {value: '113',   label: 'Республика Мордовия'},
    {value: '114',   label: 'Республика Саха (Якутия)'},
    {value: '115',   label: 'Республика Северная Осетия — Алания'},
    {value: '116',   label: 'Республика Татарстан (Татарстан)'},
    {value: '117',   label: 'Республика Тыва'},
    {value: '118',   label: 'Республика Удмуртская'},
    {value: '119',   label: 'Республика Хакасия'},
    {value: '61',    label: 'Ростов-на-дону'},
    {value: '161',   label: 'Ростовская область'},
    {value: '162',   label: 'Рязанская область'},
    {value: '62',    label: 'Рязань'},
    {value: '89',    label: 'Салехард'},
    {value: '63',    label: 'Самара'},
    {value: '163',   label: 'Самарская область'},
    {value: '78',    label: 'Санкт-Петербург'},
    {value: '178',   label: 'Ленинградская область'},
    {value: '13',    label: 'Саранск'},
    {value: '64',    label: 'Саратов'},
    {value: '164',   label: 'Саратовская область'},
    {value: '165',   label: 'Сахалинская область'},
    {value: '166',   label: 'Свердловская область'},
    {value: '92',    label: 'Севастополь'},
    {value: '82',    label: 'Симферополь'},
    {value: '67',    label: 'Смоленск'},
    {value: '167',   label: 'Смоленская область'},
    {value: '26',    label: 'Ставрополь'},
    {value: '126',   label: 'Ставропольский край'},
    {value: '11',    label: 'Сыктывкар'},
    {value: '68',    label: 'Тамбов'},
    {value: '168',   label: 'Тамбовская область'},
    {value: '169',   label: 'Тверская область'},
    {value: '69',    label: 'Тверь'},
    {value: '70',    label: 'Томск'},
    {value: '170',   label: 'Томская область'},
    {value: '71',    label: 'Тула'},
    {value: '171',   label: 'Тульская область'},
    {value: '172',   label: 'Тюменская область'},
    {value: '72',    label: 'Тюмень'},
    {value: '3',     label: 'Улан-Удэ'},
    {value: '73',    label: 'Ульяновск'},
    {value: '173',   label: 'Ульяновская область'},
    {value: '2',     label: 'Уфа'},
    {value: '27',    label: 'Хабаровск'},
    {value: '127',   label: 'Хабаровский край'},
    {value: '86',    label: 'Ханты-Мансийск'},
    {value: '186',   label: 'Ханты-Мансийский автономный округ — Югра'},
    {value: '84',    label: 'Херсон'},
    {value: '184',   label: 'Херсонская область'},
    {value: '21',    label: 'Чебоксары'},
    {value: '74',    label: 'Челябинск'},
    {value: '174',   label: 'Челябинская область'},
    {value: '9',     label: 'Черкесск'},
    {value: '195',   label: 'Чеченская республика'},
    {value: '95',    label: 'Чечня'},
    {value: '75',    label: 'Чита'},
    {value: '121',   label: 'Чувашская Республика (Чувашия)'},
    {value: '87',    label: 'Чукотский автономный округ'},
    {value: '187',   label: 'Чукотский автономный округ'},
    {value: '8',     label: 'Элиста'},
    {value: '65',    label: 'Южно-Сахалинск'},
    {value: '14',    label: 'Якутск'},
    {value: '189',   label: 'Ямало-Ненецкий автономный округ'},
    {value: '76',    label: 'Ярославль'},
    {value: '176',   label: 'Ярославская область'},
    {value: '0',     label: 'Моего региона нет в списке'},
];

export default regionCodes

/*const regionCodes = [
    {value: '01',  label: '1 - Майкоп'},
    {value: '101', label: '101 - Республика Адыгея (Адыгея)'},
    {value: '02',  label: '2 - Уфа'},
    {value: '102', label: '102 - Республика Башкортостан'},
    {value: '03',  label: '3 - Улан-Удэ'},
    {value: '103', label: '103 - Республика Бурятия'},
    {value: '04',  label: '4 - Горно-Алтайск'},
    {value: '104', label: '104 - Республика Алтай'},
    {value: '05',  label: '5 - Махачкала'},
    {value: '105', label: '105 - Республика Дагестан'},
    {value: '06',  label: '6 - Магас'},
    {value: '106', label: '106 - Республика Ингушетия'},
    {value: '07',  label: '7 - Нальчик'},
    {value: '107', label: '107 - Республика Кабардино-Балкарская'},
    {value: '08',  label: '8 - Элиста'},
    {value: '108', label: '108 - Республика Калмыкия'},
    {value: '09',  label: '9 - Черкесск'},
    {value: '109', label: '109 - Республика Карачаево-Черкесская'},
    {value: '10',  label: '10 - Петрозаводск'},
    {value: '110', label: '110 - Республика Карелия'},
    {value: '11',  label: '11 - Сыктывкар'},
    {value: '111', label: '111 - Республика Коми'},
    {value: '12',  label: '12 - Йошкар-Ола'},
    {value: '112', label: '112 - Республика Марий Эл'},
    {value: '13',  label: '13 - Саранск'},
    {value: '113', label: '113 - Республика Мордовия'},
    {value: '14',  label: '14 - Якутск'},
    {value: '114', label: '114 - Республика Саха (Якутия)'},
    {value: '15',  label: '15 - Владикавказ'},
    {value: '115', label: '115 - Республика Северная Осетия — Алания'},
    {value: '16',  label: '16 - Казань'},
    {value: '116', label: '116 - Республика Татарстан (Татарстан)'},
    {value: '17',  label: '17 - Кызыл'},
    {value: '117', label: '117 - Республика Тыва'},
    {value: '18',  label: '18 - Ижевск'},
    {value: '18',  label: '18 - Республика Удмуртская'},
    {value: '19',  label: '19 - Абакан'},
    {value: '119', label: '119 - Республика Хакасия'},
    {value: '21',  label: '21 - Чебоксары'},
    {value: '121', label: '121 - Чувашская Республика (Чувашия)'},
    {value: '22',  label: '22 - Барнаул'},
    {value: '122', label: '122 - Алтайский край'},
    {value: '23',  label: '23 - Краснодар'},
    {value: '123', label: '123 - Краснодарский край'},
    {value: '24',  label: '24 - Красноярск'},
    {value: '124', label: '124 - Красноярский край'},
    {value: '25',  label: '25 - Владивосток'},
    {value: '125', label: '125 - Приморский край'},
    {value: '26',  label: '26 - Ставрополь'},
    {value: '126', label: '126 - Ставропольский край'},
    {value: '27',  label: '27 - Хабаровск'},
    {value: '127', label: '127 - Хабаровский край'},
    {value: '28',  label: '28 - Благовещенск'},
    {value: '128', label: '128 - Амурская область'},
    {value: '29',  label: '29 - Архангельск'},
    {value: '129', label: '129 - Архангельская область'},
    {value: '30',  label: '30 - Астрахань'},
    {value: '130', label: '130 - Астраханская область'},
    {value: '31',  label: '31 - Белгород'},
    {value: '131', label: '131 - Белгородская область'},
    {value: '32',  label: '32 - Брянск'},
    {value: '132', label: '132 - Брянская область'},
    {value: '33',  label: '33 - Владимир'},
    {value: '133', label: '133 - Владимирская область'},
    {value: '34',  label: '34 - Волгоград'},
    {value: '134', label: '134 - Волгоградская область'},
    {value: '35',  label: '35 - Вологода'},
    {value: '135', label: '135 - Вологодская область'},
    {value: '36',  label: '36 - Воронеж'},
    {value: '136', label: '136 - Воронежская область'},
    {value: '37',  label: '37 - Иваново'},
    {value: '137', label: '137 - Ивановская область'},
    {value: '38',  label: '38 - Иркутск'},
    {value: '138', label: '138 - Иркутская область'},
    {value: '39',  label: '39 - Калининград'},
    {value: '139', label: '139 - Калининградская область'},
    {value: '40',  label: '40 - Калуга'},
    {value: '140', label: '140 - Калужская область'},
    {value: '41',  label: '41 - Петропавловск-Камчатский'},
    {value: '141', label: '141 - Камчатский край'},
    {value: '42',  label: '42 - Кемерово'},
    {value: '142', label: '142 - Кемеровская область'},
    {value: '43',  label: '43 - Киров'},
    {value: '143', label: '143 - Кировская область'},
    {value: '44',  label: '44 - Кострома'},
    {value: '144', label: '144 - Костромская область'},
    {value: '45',  label: '45 - Курган'},
    {value: '145', label: '145 - Курганская область'},
    {value: '46',  label: '46 - Курск'},
    {value: '146', label: '146 - Курская область'},
    {value: '48',  label: '48 - Липецк'},
    {value: '148', label: '148 - Липецкая область'},
    {value: '49',  label: '49 - Магадан'},
    {value: '149', label: '149 - Магаданская область'},
    {value: '51',  label: '51 - Мурманск'},
    {value: '151', label: '151 - Мурманская область'},
    {value: '52',  label: '52 - Нижний Новгород'},
    {value: '152', label: '152 - Нижегородская область'},
    {value: '53',  label: '53 - Великий Новгород'},
    {value: '153', label: '153 - Новгородская область'},
    {value: '54',  label: '54 - Новосибирск'},
    {value: '154', label: '154 - Новосибирская область'},
    {value: '55',  label: '55 - Омск'},
    {value: '155', label: '155 - Омская область'},
    {value: '56',  label: '56 - Оренбург'},
    {value: '156', label: '156 - Оренбургская область'},
    {value: '57',  label: '57 - Орёл'},
    {value: '157', label: '157 - Орловская область'},
    {value: '58',  label: '58 - Пенза'},
    {value: '158', label: '158 - Пензенская область'},
    {value: '59',  label: '59 - Пермь'},
    {value: '159', label: '159 - Пермский край'},
    {value: '60',  label: '60 - Псков'},
    {value: '160', label: '160 - Псковская область'},
    {value: '61',  label: '61 - Ростов-на-дону'},
    {value: '161', label: '161 - Ростовская область'},
    {value: '62',  label: '62 - Рязань'},
    {value: '162', label: '162 - Рязанская область'},
    {value: '63',  label: '63 - Самара'},
    {value: '163', label: '163 - Самарская область'},
    {value: '64',  label: '64 - Саратов'},
    {value: '164', label: '164 - Саратовская область'},
    {value: '65',  label: '65 - Южно-Сахалинск'},
    {value: '165', label: '165 - Сахалинская область'},
    {value: '66',  label: '66 - Екатеринбург'},
    {value: '166', label: '166 - Свердловская область'},
    {value: '67',  label: '67 - Смоленск'},
    {value: '167', label: '167 - Смоленская область'},
    {value: '68',  label: '68 - Тамбов'},
    {value: '168', label: '168 - Тамбовская область'},
    {value: '69',  label: '69 - Тверь'},
    {value: '169', label: '169 - Тверская область'},
    {value: '70',  label: '70 - Томск'},
    {value: '170', label: '170 - Томская область'},
    {value: '71',  label: '71 - Тула'},
    {value: '171', label: '171 - Тульская область'},
    {value: '72',  label: '72 - Тюмень'},
    {value: '172', label: '172 - Тюменская область'},
    {value: '73',  label: '73 - Ульяновск'},
    {value: '173', label: '173 - Ульяновская область'},
    {value: '74',  label: '74 - Челябинск'},
    {value: '174', label: '174 - Челябинская область'},
    {value: '75',  label: '75 - Чита'},
    {value: '175', label: '175 - Забайкальский край'},
    {value: '76',  label: '76 - Ярославль'},
    {value: '176', label: '176 - Ярославская область'},
    {value: '77',  label: '77 - Москва'},
    {value: '177', label: '177 - Московская область'},
    {value: '78',  label: '78 - Санкт-Петербург'},
    {value: '178', label: '178 - Ленинградская область'},
    {value: '79',  label: '79 - Биробиджан'},
    {value: '179', label: '179 - Еврейская автономная область'},
    {value: '80',  label: '80 - Донецк'},
    {value: '180', label: '180 - Донецкая Народная Республика'},
    {value: '81',  label: '81 - Луганск'},
    {value: '181', label: '181 - Луганская Народная Республика'},
    {value: '82',  label: '82 - Симферополь'},
    {value: '182', label: '182 - Республика Крым'},
    {value: '83',  label: '83 - Нарьян-Мар'},
    {value: '183', label: '183 - Ненецкий автономный округ'},
    {value: '84',  label: '84 - Херсон'},
    {value: '184', label: '184 - Херсонская область'},
    {value: '85',  label: '85 - Запорожье'},
    {value: '185', label: '185 - Запорожская область'},
    {value: '86',  label: '86 - Ханты-Мансийск'},
    {value: '186', label: '186 - Ханты-Мансийский автономный округ — Югра'},
    {value: '87',  label: '87 - Чукотский автономный округ'},
    {value: '187', label: '187 - Чукотский автономный округ'},
    {value: '89',  label: '89 - Салехард'},
    {value: '189', label: '189 - Ямало-Ненецкий автономный округ'},
    {value: '92',  label: '92 - Севастополь'},
    {value: '95',  label: '95 - Чечня'},
    {value: '195', label: '195 - Чеченская республика'},
    {value: '94',  label: '94 - Байконур'},
    {value: '0',   label: 'Моего региона нет в списке'},
]*/