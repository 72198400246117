import { io } from 'socket.io-client';
//const socket = io('/api/im');

const socket = io('/api/im', {
    transports: ['websocket'],  // Убедитесь, что используется WebSocket
    path: '/api/im',  // Путь к вашему WebSocket соединению
});
socket.on('connect', () => {
    console.log('Connected to WebSocket server');
});
export default socket;