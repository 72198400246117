import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";

interface CheBoxProps extends FormInputProps {
    options: Options[]
}

type Options = { value: string; label?: string }
const FormInputDropdown : React.FC<CheBoxProps> = ({
   name,
   control,
   label,
   options,
   defaultValue
}) => {
    const generateSingleOptions = () => {
        return options.map((option) => {
            return (
                <MenuItem key={option.value} value={option.value}>
                    {(option.label ? option.label : option.value)}
                </MenuItem>
            );
        });
    };

    return (
        <FormControl style={{ width: "100%" }}>
            <InputLabel id={`${label}_label`}>{label}</InputLabel>
            <Controller
                defaultValue={defaultValue}
                render={({ field: { onChange, value }, fieldState }) => (
                    <React.Fragment>
                        <Select
                            labelId={`${label}_label`}
                            label={label}
                            onChange={onChange}
                            value={value}
                            //error={!!fieldState.error}
                        >
                            {generateSingleOptions()}
                        </Select>
                        {/*{fieldState.error ? (
                            <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                        ) : null}*/}
                    </React.Fragment>
                )}
                control={control}
                name={name}
                //rules={{ required: `Пожалуйста, выберите "${label}"` }}
            />
        </FormControl>
    );
};

export default FormInputDropdown;
